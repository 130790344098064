






































































import './scss/TaskRelatedSubject.scss';
import './scss/TaskRelatedSubjectAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IDocument } from '@store/modules/project-documents/Interfaces';
import { ISubject } from '@store/modules/project-tasks/interfaces/Interfaces';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import BaseLink from "@components/BaseLink/BaseLink.vue";
import SlideUpDown from 'vue-slide-up-down';

const NSDocuments = namespace('storeProjectDocuments');
const NSProject = namespace('storeProject');

@Component({
    name: 'TaskRelatedSubject',
    components: { BaseLink, SlideUpDown },
})

export default class TaskRelatedSubject extends Vue {
    @Prop() taskSubject!: ISubject | null;

    @NSProject.Getter('projectId') projectId!: number;
    @NSDocuments.Getter('documents') projectDocuments!: IDocument[];
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_DOC_FILES) getDocFiles!:
        (payload: { appId: number, documentId: string }) => Promise<any>;
    @NSDocuments.Action(ProjectDocumentsActions.A_DOWNLOAD_FILES_ZIP) downloadFilesZip!: (payload: {
        data: {
            appId: string | number;
            ids: string;
        },
        nameArchive: string,
    }) => Promise<void>;
    documentFiles = [];
    isSubjectOpened: boolean = false;
    appearanceDuration = 300;

    toggleTaskSubject() {
        if (!this.documentFiles.length) {
            this.getDocFiles({
                appId: this.projectId,
                documentId: this.taskRelatedDocument?.uuid || '',
            })
            .then((files) => {
                this.documentFiles = files;
                this.isSubjectOpened = !this.isSubjectOpened;
            });
        } else {
            this.isSubjectOpened = !this.isSubjectOpened;
        }
    }

    get taskRelatedDocument() {
        if (this.taskSubject?.systemType === 'ДокументацияПоПроекту') {
            return this.projectDocuments.find(document => document.uuid === this.taskSubject?.uuid);
        }
        return null;
    }

    downloadFiles() {
        if (this.taskRelatedDocument) {
            this.downloadFilesZip({
                data: {
                    appId: this.projectId,
                    ids: this.taskRelatedDocument.uuid,
                },
                nameArchive: this.taskRelatedDocument.title,
            });
        }
    }
}
